<template>
  <v-app>
    <v-banner
        single-line
        height="400"
        class="children-banner"
    >
      <v-carousel height="100%"
                  class="carousel-new carousel-banner"
                  hide-delimiter-background
                  hide-delimiters
                  :show-arrows="false">
        <v-carousel-item v-for="(banner,index) in bannerList" :key="index">
          <v-img
              :src="banner.img"
              height="100%"
          >
            <template v-slot:placeholder>
              <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
              >
                <v-progress-circular
                    indeterminate
                    size="80"
                    width="8"
                    color="grey lighten-3"
                ></v-progress-circular>
              </v-row>
            </template>
            <v-container color="transparent" class="pa-0 max-width-1200 height-per-100 d-flex align-center justify-center">
              <v-card color="transparent" class="ml-0" flat>
                <v-card-title class="pa-0 font-size-banner-title family-Bold justify-center" :style="'color: ' + banner.titleColor + '!important;'">
                  <div>
                    <div v-for="(title,tipIndex) in banner.title" :key="tipIndex" class="pt-2" style="font-weight: 500">{{title}}</div>
                  </div>
                </v-card-title>
                <v-card-title class="pa-0 font-size-banner-tip mt-4 justify-center" :style="'color: ' + banner.titleColor + '!important;'">
                  {{banner.tip}}
                </v-card-title>
                <v-card-actions class="pa-0 mt-14 justify-center" v-if="banner.btn">
                  <v-btn
                      rounded
                      color="#0568FD"
                      width="160"
                      height="50"
                      class="font-size-20"
                      @click.stop="handleBannerBtn(banner.router)"
                  >
                    <span class="color-FFF">{{ banner.btn }}</span>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-container>
          </v-img>
        </v-carousel-item>
      </v-carousel>
    </v-banner>
    <!-- 产品业务介绍 -->
    <div class="business-introduction">
      <v-card class="max-width-1200" flat>
        <div class="public-title">
          <p>BUSINESS INTRODUCTION</p>
          <div>
            <span>产品业务介绍</span>
            <span>开创互动广告全新形式</span>
          </div>
        </div>
        <v-tabs v-model="tab" centered color="#0084ff" class="g-tabs pt-6">
          <v-tab v-for="(v, k) in data.second" :key="k" >
            <b>{{ v.tabTitle }}</b>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="(v, k) in data.second" :key="k" :transition="false">
            <div class="box">
              <transition name="slide-fade" appear>
                <div class="left" v-show="tab == k">
                  <v-card-text class="title" v-text="v.lunTitle" />
                  <v-card-text class="title" v-text="v.lunTitle1" />
                  <v-card-text class="text mt-4" v-text="v.tip" />
                  <div>
                    <div class="tab" v-cloak>
                      <span
                          v-for="(item,index) in v.btns"
                          :key="item.id"
                          :class='currentIndex==index?"active":""'
                          @click="change(index)">
                        {{item.button}}
                      </span>
                    </div>
                  </div>
                </div>
              </transition>
              <transition name="slide-fade-reverse" appear>
                <div v-show="tab == k" >
                  <div
                      v-for="(m,index) in v.btns"
                      :key="index"
                      class="right"
                      v-show="currentIndex==index"
                  >
                    <v-img
                        class="iphone-item d-flex align-center" height="100%"
                        :src="m.imgUrl"
                    >
                      <img :class="m.id == 1? 'item-img-big' : ''"   :src="m.src1" alt="" v-show="m.id==1">
                      <img :class="m.id == 2? 'item-img-big2' : ''"  :src="m.src1" alt="" v-show="m.id==2">
                      <img :class="m.id == 3? 'item-img-big3' : ''"  :src="m.src1" alt="" v-show="m.id==3">
                      <img :class="m.id == 4? 'item-img-big4' : ''"  :src="m.src1" alt="" v-show="m.id==4">
                    </v-img>
                  </div>
                </div>
              </transition>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </div>
    <div class="d-flex justify-center pb-15">
      <v-card class="max-width-1200" flat>
        <div class="public-title mt-3">
          <p>ADVERTISING PROCESS</p>
          <div>
            <span>广告投放流程</span>
            <span>让你的投放更省心</span>
          </div>
        </div>
        <div class="mt-8">
          <img style="height:120px;" src="https://h5.ophyer.cn/official_website/other/marketingDeversion01.png"/>
        </div>
      </v-card>
    </div>
    <div class="d-flex justify-center pb-9" style="background-color: #F5F9FD;">
      <v-card class="max-width-1200" width="100%" flat color="transparent">
        <div class="public-title">
          <p>COOPERATE</p>
          <div>
            <span>整合主流渠道，媒体推广全方位</span>
            <span>聚集TOP媒体的优质流量，帮助广告主实现最大范围用户覆盖</span>
          </div>
        </div>
        <v-img class="" contain src="https://h5.ophyer.cn/official_website/other/marketingDeversion02.png"/>
      </v-card>
    </div>
    <transition name="fade" mode="out-in">
      <div class="dialog" v-show="showDialog" @click.stop>
        <div class="">
          <div class="close-btn" @click.stop="showDialog = false;">
            <v-img class="icon" src="../../../public/img/icons/menu/close.png"
                   max-width="12" height="12" contain></v-img>
          </div>
          <div class="d-flex justify-center font-size-24 pa-0 mt-6">立即咨询</div>
          <div class="d-flex justify-center mt-10">
            <div>
              <v-img src="@/assets/login/qrCode-fujingli.jpg" width="200" height="200" contin></v-img>
              <v-card-text class="text-align-center font-size-14 pa-0 color-666" style="margin-top: -3px">扫一扫加我微信</v-card-text>
            </div>
            <div class="ml-6 mt-2">
              <v-row class="pa-0 align-center">
                <v-img max-width="46" height="46" contain src="../../../public/img/icons/menu/contacts.png"></v-img>
                <v-card flat class="ml-3 mt-1">
                  <v-card-title class="font-size-18 pa-0">联系人</v-card-title>
                  <v-card-text class="font-size-14 color-999 pa-0 mt-1">付经理</v-card-text>
                </v-card>
              </v-row>
              <v-row class="pa-0 mt-7">
                <v-img max-width="46" height="46" contain src="../../../public/img/icons/menu/contact-phone.png"></v-img>
                <v-card flat class="ml-3 mt-1">
                  <v-card-title class="font-size-18 pa-0">联系电话</v-card-title>
                  <v-card-text class="font-size-14 color-999 pa-0 mt-1">13671291345</v-card-text>
                </v-card>
              </v-row>
              <v-row class="pa-0 mt-7">
                <v-img max-width="46" height="46" contain src="../../../public/img/icons/menu/contact-email.png"></v-img>
                <v-card flat class="ml-3 mt-1">
                  <v-card-title class="font-size-18 pa-0">邮箱</v-card-title>
                  <v-card-text class="font-size-14 color-999 pa-0 mt-1">minghao.fu@ophyer.com</v-card-text>
                </v-card>
              </v-row>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </v-app>
</template>

<script>
import qs from 'qs'
import contentData from '@/static/contentData.js'

export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
      tab:null,
      bannerList: [
        {
          img: "https://h5.ophyer.cn/official_website/banner/marketingDeversion-banner.png",
          title: ["结合AR/VR沉浸式互动体验 构建广告新生态"],
          titleColor:"#FFFFFF",
          tip: "聚合国内主流媒体，结合AR/VR趣味内容，轻松为广告找到优质用户",
          btn: "申请试用",
        }
      ],
      data: contentData.EfficiencyCloud,
      currentIndex: 0,
      showDialog: false
    }
  },
  created() {
    let _this = this;
    document.addEventListener("click",()=>{
      _this.showDialog = false;
    })
  },
  methods: {
    change(index){
      this.currentIndex = index;
    },
    handleBannerBtn(router) {
      if(router){
        window.open(window.location.protocol + "//" + window.location.host + router);
      }else {
        this.showDialog = true;
      }
    },
    goCaseDetail:(item) =>{
      let query = {
        source: 'qita',
        anli: item.self
      }
      let url = '/CaseDetail?'+qs.stringify(query)
      window.open(url, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog{
  z-index: 3;
  width: 550px;
  height: 380px;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  background-color: #FFFFFF;
  box-shadow: 0px 0px 15px 5px rgb(0 114 255 / 10%);
  .close-btn{
    .icon {
      cursor: pointer;
      top: 10px;
      left: 470px;
    }
  }
  ::v-deep .v-card--link {
    cursor: unset;
  }
}
.business-introduction {
  display: flex;
  justify-content: center;
  .box {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .left {
    text-align: left;
    margin-right: 85px;
    .v-card__text {
      padding: 0;
    }
    .title {
      font-size: 24px !important;
      color: #333;
      line-height: 34px;
      width: 280px;
      font-weight: 500;
    }
    .tip {
      margin: 12px 0 35px;
      font-size: 16px;
      color: #666666;
    }
    .text {
      font-size: 16px;
      width: 366px;
      line-height: 26px;
    }
  }
  .right{
    width: 293px;
    height: 519px;
    position: relative;
  }
  .tab {
    height: 120px;
    span{
      margin: 50px 10px 0px 0px;
      box-sizing: border-box;
      float: left;
      list-style: none;
      cursor: pointer;
      text-align: center;
      width: 120px;
      height: 40px;
      background: #e0ebef;
      border-radius: 20px;
      font-size: 14px;
      font-weight: 400;color: #666666;
      line-height: 40px;
      text-align: center;
      cursor: pointer;
    }
    .active{
      width: 120px;
      height: 40px;
      background: #3288FF;
      border-radius: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 40px;
      text-align: center;
      cursor: pointer;
    }
  }
}
.item-img-big {
  width: 176px;
  height: 80px;
  position: absolute;
  top: 102px;
  right:59px;
  animation-delay: 3s;
  animation-fill-mode:forwards;
  // 关键帧名称
  -webkit-animation-name:scaleDraw;
  // 动画的速度曲线
  -webkit-animation-timing-function:ease-in-out;
  // 动画播放的次数
  -webkit-animation-iteration-count:infinite;
  // 动画所花费的时间
  -webkit-animation-duration:4s;
}

.item-img-big2{
  width: 143px;
  height: 69px;
  position: absolute;
  top: 296px;
  right:76px;
  animation-delay: 1s;
  animation-fill-mode:forwards;
  // 关键帧名称
  -webkit-animation-name:scaleDraw;
  // 动画的速度曲线
  -webkit-animation-timing-function:ease-in-out;
  // 动画播放的次数
  -webkit-animation-iteration-count:infinite;
  // 动画所花费的时间
  -webkit-animation-duration:4s;
}

.item-img-big3{
  width: 195px;
  height: 291px;
  position: absolute;
  top: 97px;
  right:49px;
  border-radius: 10px;
  animation-delay: 3s;
  animation-fill-mode:forwards;
  // 关键帧名称
  -webkit-animation-name:scaleDraw1;
  // 动画的速度曲线
  -webkit-animation-timing-function:ease-in-out;
  // 动画播放的次数
  -webkit-animation-iteration-count:infinite;
  // 动画所花费的时间
  -webkit-animation-duration:5s;
}

.item-img-big4{
  width: 622px;
  height: 354px;
  position: absolute;
  top: 96px;
  right:-160px;
  animation-delay: 1s;
  z-index: -20;
  // animation-fill-mode:forwards;
  // 关键帧名称
  -webkit-animation-name:move;
  // 动画的速度曲线
  -webkit-animation-timing-function:ease-in-out;
  // 动画播放的次数
  -webkit-animation-iteration-count:infinite;
  // // 动画所花费的时间
  -webkit-animation-duration:10s;
}
</style>
